import React, { InputHTMLAttributes } from 'react';
import { NumericFormat } from 'react-number-format';
import type { InternalNumberFormatBase } from 'react-number-format/types/types';
import { Input, type Theme } from 'theme-ui';

type Props = {} & InternalNumberFormatBase &
  InputHTMLAttributes<HTMLInputElement>;

const AmountInput = ({ ...rest }: Props) => {
  return (
    <NumericFormat
      sx={{
        p: 3,
        fontSize: 2,
        fontWeight: 'bold',
        color: 'quaternary',
        border: (theme: Theme) => `1px solid ${theme?.colors?.disabledButton}`,
        maxWidth: '200px',
        '::placeholder': {
          fontSize: 2,
          fontWeight: 'bold',
        },
        ':focus-visible': {
          border: (theme: Theme) => `1px solid ${theme?.colors?.primary}`,
          outline: 'none',
        }
      }}
      customInput={Input}
      prefix="€ "
      decimalScale={2}
      inputMode="decimal"
      allowNegative={false}
      thousandSeparator={'.'}
      decimalSeparator={','}
      {...rest}
    />
  );
};

export default AmountInput;
