import React, { useEffect } from 'react';
import { Box, Flex, Text, useColorMode } from 'theme-ui';

import { useIntl } from 'context/IntlContext';
import TopCTA from '../../components/TopCTA';

import Section from '../../components/Section';
import Seo from '../../components/SEO';

import Form from './components/Form';

const PAGE_COLOR_MODE = 'default';

const BearLossCalculator = () => {
  const { t } = useIntl();
  const [colorMode, setColorMode] = useColorMode();

  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);
  return (
    <>
      <Seo
        title={t('global.meta.title')}
        description={t('global.meta.description')}
      />
      <Section
        //@ts-ignore
        innerSX={{
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
        }}
        sx={{ p: '0px !important' }}
      >
        <Box
          sx={{
            py: [9, 9, 9, 9, 15],
            px: 7,
            textAlign: ['initial', 'initial', 'initial', 'initial', 'center'],
            backgroundColor: 'secondaryBackground',
            width: '100%',
          }}
        >
          <Flex
            sx={{
              p: '0',
              m: 'auto',
              width: '100%',
              maxWidth: '1024px',
              justifyContent: 'space-around',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              gap: ['30px', '30px', '30px', '30px', '60px'],
            }}
          >
            <Box
              sx={{
                p: '0px',
                flex: 1,
              }}
            >
              <Text
                sx={{
                  display: 'block',
                  textAlign: 'start',
                  p: 0,
                }}
                variant="sectionTitle"
              >
                {t('Ability_to_Bear_Loss_Calculator.Title')}
              </Text>
              <Text
                variant="sectionSubtitle"
                sx={{
                  p: 0,
                  paddingBottom: '0 !important',
                  display: 'block',
                  textAlign: 'start',
                  marginTop: [5, 5, 5, 5, 10],
                }}
              >
                {t('Ability_to_Bear_Loss_Calculator.Subtitle')}
              </Text>
            </Box>
            <Form />
          </Flex>
        </Box>
      </Section>
    </>
  );
};

export default BearLossCalculator;
