import React, { useState } from 'react';
import { Box, Flex, Label, Text } from 'theme-ui';
import AmountInput from './AmountInput';
import theme from 'theme';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'context/IntlContext';

// 'de-DE' so we have ',' as decimal separator and '.' as thousands separator
const numberFormatter = new Intl.NumberFormat('de-DE');
const PERCENTAGE_TO_CALCULATE = 10;

interface FormData {
  annualIncome: number;
  totalAssets: number;
  monthlyCommitments: number;
  yearlyCommitments: number;
}

const initialState: FormData = {
  annualIncome: 0,
  totalAssets: 0,
  monthlyCommitments: 0,
  yearlyCommitments: 0,
};

const Form = () => {
  const [formData, setFormData] = useState<FormData>(initialState);

  const { t } = useIntl();
  const { annualIncome, monthlyCommitments, totalAssets, yearlyCommitments } =
    formData;

  const financialCommitments = monthlyCommitments * 12 + yearlyCommitments;
  const netWorth = annualIncome + totalAssets - financialCommitments;
  const abilityToBearLoss = (netWorth * PERCENTAGE_TO_CALCULATE) / 100;

  const isTouched = formData !== initialState;

  return (
    <Box
      sx={{
        p: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        border: (theme) => `1px solid ${theme?.colors?.disabledButton}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flex: 1,
      }}
      as="form"
      onSubmit={(e) => e.preventDefault()}
    >
      <Text
        sx={{
          display: 'block',
          textAlign: 'start',
          fontSize: 5,
        }}
        variant="heading"
      >
        {t('Ability_to_Bear_Loss_Calculator.Your_Information')}
      </Text>
      <Box>
        <Text
          sx={{
            display: 'block',
            textAlign: 'start',
            color: 'quaternary',
            marginTop: 3,
          }}
          variant="heading"
        >
          {t('Ability_to_Bear_Loss_Calculator.Income')}
        </Text>
        <Label htmlFor="annualIncome" sx={{ marginTop: 3 }}>
          {t('Ability_to_Bear_Loss_Calculator.Annual_Income')}
        </Label>
        <AmountInput
          required
          placeholder="€ 20.000"
          id="annualIncome"
          onValueChange={(e) =>
            setFormData({ ...formData, annualIncome: Number(e.value) })
          }
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Text
          sx={{
            display: 'block',
            textAlign: 'start',
            color: 'quaternary',
            marginTop: 3,
          }}
          variant="heading"
        >
          {t('Ability_to_Bear_Loss_Calculator.Assets')}
        </Text>
        <Label htmlFor="totalAssets" sx={{ marginTop: 3 }}>
          {t('Ability_to_Bear_Loss_Calculator.Total_Assets')}
        </Label>
        <AmountInput
          required
          placeholder="€ 30.000"
          id="totalAssets"
          onValueChange={(e) =>
            setFormData({ ...formData, totalAssets: Number(e.value) })
          }
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Text
          sx={{
            display: 'block',
            textAlign: 'start',
            color: 'quaternary',
            marginTop: 3,
          }}
          variant="heading"
        >
          {t(
            'Ability_to_Bear_Loss_Calculator.Financial_Commitments_(Expenses)',
          )}
        </Text>
        <Flex sx={{ gap: 3 }}>
          <Flex sx={{ flexDirection: 'column' }}>
            <Label htmlFor="monthlyCommitments" sx={{ marginTop: 3 }}>
              {t('Ability_to_Bear_Loss_Calculator.Monthly')}
            </Label>
            <AmountInput
              required
              placeholder="€ 30.000"
              id="monthlyCommitments"
              onValueChange={(e) =>
                setFormData({
                  ...formData,
                  monthlyCommitments: Number(e.value),
                })
              }
            />
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Label htmlFor="yearlyCommitments" sx={{ marginTop: 3 }}>
              {t('Ability_to_Bear_Loss_Calculator.Yearly')}
            </Label>
            <AmountInput
              required
              placeholder="€ 30.000"
              id="yearlyCommitments"
              onValueChange={(e) =>
                setFormData({ ...formData, yearlyCommitments: Number(e.value) })
              }
            />
          </Flex>
        </Flex>
      </Box>
      {isTouched && (
        <>
          <hr
            style={{
              width: '100%',
              marginTop: '20px',
              marginBottom: '10px',
              border: `1px solid ${theme?.colors?.disabledButton}`,
            }}
          />
          <Results abilityToBearLoss={abilityToBearLoss} netWorth={netWorth} />
        </>
      )}
    </Box>
  );
};

interface ResultsProps {
  netWorth: number;
  abilityToBearLoss: number;
}

const Results = ({ netWorth, abilityToBearLoss }: ResultsProps) => {
  const { t } = useIntl();

  return (
    <>
      <Text
        sx={{
          display: 'block',
          textAlign: 'start',
          fontSize: 5,
        }}
        variant="heading"
      >
        {t('Ability_to_Bear_Loss_Calculator.Your_Results')}
      </Text>
      <span style={{ textAlign: 'start', marginTop: '16px' }}>
        <Text sx={{ display: 'inline', fontSize: [3, 3, 3, 3, 4] }}>
          <FormattedMessage
            id="Ability_to_Bear_Loss_Calculator.results.total_net_worth"
            values={{
              b: (chunks: string) => <b>{chunks}</b>,
            }}
          />
          {numberFormatter.format(netWorth)}{' '}
        </Text>
        <Text sx={{ display: 'inline', fontSize: [3, 3, 3, 3, 4] }}>
          <FormattedMessage
            id="Ability_to_Bear_Loss_Calculator.results.ability_to_bear_loss"
            values={{
              b: (chunks: string) => <b>{chunks}</b>,
            }}
          />
          {numberFormatter.format(abilityToBearLoss)}
        </Text>
      </span>
    </>
  );
};

export default Form;
